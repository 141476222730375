








































import { computed, defineComponent, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

interface FormError {
  error: string;
}

export default defineComponent({
  components: {
    OPrivacyAdd: () => import('@/components/organisms/globalTools/terms/privacy/o-privacy-add.vue'),
  },

  setup(props, { root }) {

    const model = reactive<{
      data: any;
    }>({
      data: {
        name: '',
        template: '',
        type: 'privacy policy'
      }
    })

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): FormError[] => {
        const errors: FormError[] = []

        if (!model.data.name) errors.push({ error: 'Podaj nazwę szablonu.' })

        return errors
      }),
    })

    const isFormValid = computed(() => !state.errors.length)

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance


      const data = {
        name: model.data.name,
        template: model.data.template || undefined,
        type: 'privacy policy'
      }

      state.loading = true

      axiosInstance
        .post('consent', data)
        .then(() => {

          state.success = true
          model.data = {
            name: '',
            template: '',
            type: 'privacy policy'
          }
        })
        .catch(error => {
          state.error = error.response.status
        })
        .finally(() => state.loading = false)
    }

    return {
      model,
      state,
      isFormValid,
      onSubmit
    }
  }
})
